import React from "react";

interface RewardEntry {
    rewardDate: string;
    reward: number;
    avgHash: number;
}


interface RevenueRewardProps {
    rewards: RewardEntry[];
    coin: string;
}

const RevenueReward: React.FC<RevenueRewardProps> = ({ rewards, coin }) => {
    

    return (
        <div className="p-4">
            <div className="overflow-x-auto w-full">
                <table className="min-w-full text-gray-500 text-center">
                    <thead className="text-xs text-gray-700 uppercase bg-[#D5EEFF] border">
                        <tr>
                            <th scope="col" className="px-1 py-2 md:px-6 md:py-3 text-xs md:text-sm">Reward Date</th>
                            <th scope="col" className="px-1 py-2 md:px-6 md:py-3 text-xs md:text-sm">Avg Hashrate</th>
                            <th scope="col" className="px-1 py-2 md:px-6 md:py-3 text-xs md:text-sm">Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rewards.length === 0 ? (
                            <tr>
                                <td colSpan={4} className="text-center py-4">No data available</td>
                            </tr>
                        ) : (
                        rewards.map((entry, index) => (
                            <tr key={index} className="bg-white border m-1 mb-4">
                                <td className="px-1 py-2 md:px-6 md:py-3 text-xs md:text-sm">{new Date(entry.rewardDate).toLocaleDateString()}</td>
                                <td className="px-1 py-2 md:px-6 md:py-3 text-xs md:text-sm">{entry.avgHash.toFixed(3)} Th</td>
                                <td className="px-1 py-2 md:px-6 md:py-3 text-xs md:text-sm">{coin === "BTC" ? `${entry.reward} BTC` : `${entry.reward} ${coin}`}</td>
                            </tr>
                        ))
                    )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default RevenueReward;
